/* defult styles ............................................. */

@font-face {
    font-family: 'IRANYekanX';
    src: url('./../Fonts/IRANYekanX.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'IRANYekanXFaNum';
    src: url('./../Fonts/IRANYekanXFaNum-Medium.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'IRANYekanXFaBold';
    src: url('./../Fonts/IRANYekanX-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}


*{
    box-sizing: border-box !important;
    font-family: IRANYekanX !important;
    line-height: 1.8rem;
}


.number-bold{
    font-family: IRANYekanXFaBold !important;
    font-weight: 800;
}


:root {
    --green: #038573;
}

html {
    scroll-behavior: smooth;
}


.number-farsi{
    font-family: IRANYekanXFaNum !important;
}


body {
    overflow-x: hidden !important;
    color: #424750;
    font-size: 16px;
    font-weight: 500;
    font-family: IRANYekanX !important;
}


body::-webkit-scrollbar {
    width: 6px;
    border-radius: 100px;
}


body::-webkit-scrollbar-track {
    background: #dedede;
    border-radius: 100px;
}


body::-webkit-scrollbar-thumb {
    background: #00d1b5;
    border-radius: 100px;
}


body::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 100px;
}

.no-scroll {
    overflow: hidden;
}


.scrooler-costom::-webkit-scrollbar {
    width: 6px;
    border-radius: 100px;
}


.scrooler-costom::-webkit-scrollbar-track {
    background: #dedede;
    border-radius: 100px;
}


.scrooler-costom::-webkit-scrollbar-thumb {
    background: #00d1b5;
    border-radius: 100px;
}


.scrooler-costom::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 100px;
}


#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}


input:focus {
    outline: none;
}


select:focus {
    outline: none;
}


textarea:focus {
    outline: none;
}

main {
    animation: zoomin 0.5s ease-in-out;
    min-height: 60vh;
}

@keyframes zoomin {
    0% {
        opacity: 0;
        scale: 0.9;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}


.hide-defult-style-input-number {
    -moz-appearance: textfield !important;
}


.hide-defult-style-input-number::-webkit-inner-spin-button,
.hide-defult-style-input-number::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}


.input-hover{
    transition: 0.2s;
}


.input-hover:hover{
    border-color: #dfdfdf;
}


.input-hover:focus {
    border-color:#089584c6;
}


.btn-hover{
    transition: 0.2s;
    position: relative;
    overflow: hidden;
}


.btn-hover:hover {
    background-color: #18af9e;
}


.btn-hover:hover {
    background-color: #18af9e;
}

.btn-hover:active {
    transform: scale(0.95);
}

.btn-click:active {
    transform: scale(0.95) !important;
}

.icon-hover{
    transition: 0.3s;
}

.icon-hover:hover{
    transform: scale(1.10);
}

/* styles ................................................... */

footer{
    background-color: #FAFAFA;
    box-shadow: 0px 4px 10.9px 0px #0000001A;
    border-top: 2px solid #0000001A;
    margin-top: auto;
    padding: 2rem 12rem 2rem 12rem;
    position: relative;
}


#header-home {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;
    background-color: #FAFAFA;
    box-shadow: 0px 4px 0px 0px #0C0C0C12;
    width: 100vw;
}


header .active-header{
    color: var(--green);
}


header .active-header svg path{
    fill: var(--green);
}


aside .active-header{
    color: var(--green);
}


aside .active-header svg path{
    fill: var(--green);
}


#sidbar-main-mobile {
    display: none;
}


#sidbar-category-mobile {
    display: none;
}


#close-sidbar-search-main {
    display: none;
}


#close-sidbar-category-main {
    display: none;
}


.slider-home{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}


.slider-home .swiper-slide-active img {
    transition: 0.3s;
}


.slider-home .swiper-slide-prev img,
.slider-home .swiper-slide-next img {
    transition: 0.3s;
}


.slider-home .swiper-slide-prev img,
.slider-home .swiper-slide-next img{
    transform: scale(0.9);
    opacity: 0.7;
}


.slider-home .swiper-pagination{
    bottom: 3.5rem !important;
    left: auto !important;
    background-color: #21212199;
    border-radius: 100px;
    width: max-content !important;
    padding: 0rem 0.5rem;
}


.slider-home .swiper-pagination-bullet {
    background-color: #FFFFFF;
}


.slider-home .swiper-button-prev{
    background-color: #21212199 !important;
    border-radius: 100px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
    right: calc(20% + 2.75rem) !important;
}


.slider-home .swiper-button-prev::after {
    color: white !important;
    font-size: 1.2rem;
    font-weight: 900;
    margin-right: -0.2rem;
}



.slider-home .swiper-button-next {
    background-color: #21212199 !important;
    border-radius: 100px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
    left: calc(20% + 2.75rem) !important;
}



.slider-home .swiper-button-next::after {
    color: white !important;
    font-size: 1.2rem;
    font-weight: 900;
    margin-left: -0.2rem;
}


.bg-icon-premium{
    background: linear-gradient(270deg, #089584 22.92%, #4ECDC4 100%);
}


.slider-shop .swiper-button-prev {
    background-color: white !important;
    border: 1px solid #E0E0E2 !important;
    box-shadow: 0px 2px 4px 0px #0000001A !important;
    opacity: 1 !important;
    border-radius: 100px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
}


.slider-shop .swiper-button-prev::after {
    color: #303030 !important;
    font-size: 1rem;
    font-weight: 900;
    margin-right: -0.2rem;
}


.slider-shop .swiper-button-next {
    background-color: white !important;
    border: 1px solid #E0E0E2 !important;
    box-shadow: 0px 2px 4px 0px #0000001A !important;
    opacity: 1 !important;
    border-radius: 100px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
}


.slider-shop .swiper-button-next::after {
    color: #303030 !important;
    font-size: 1rem;
    font-weight: 900;
    margin-left: -0.2rem;
}


.active-input-radio-premuim{
    background-color: #292F36 !important;
    border: none !important;
    color: white !important;
}


.active-input-radio-premuim p{
    color: white !important;
}


.form-premuim-radio input[type='radio']{
    width: 1.3rem;
    height: 1.3rem;
    border: 2px solid #BFC1C3 !important;
}


.form-premuim-radio input[type='radio']:checked {
    accent-color: #4ECDC4;
}


.li-style-text li{
    list-style-type: circle;
    padding-right: 0rem !important;
    margin-right: 2rem !important;
}


.item-team-neireez-social{
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    cursor: pointer;
    background-color: white;
    box-shadow: 0px 0px 6px 0px #00000033;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
}


.item-team-neireez-social:hover {
    transform: scale(1.2);
}


.active-drop-down-faq-header svg{
    transform: rotate(180deg);
}


.active-navbar-shop p{
    color: #089584 !important;
}


.color-product-input{
    width: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    border-radius: 100px;
    border: 1px solid #DDDDDD;
    margin-left: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.color-product-input > div{
    width: 1.8rem;
    min-width: 1.8rem;
    height: 1.8rem;
    border-radius: 100px;
    border: 1px solid #030A161A;
    display: flex;
    justify-content: center;
    align-items: center;
}


.color-product-input-active >div {
    border: 3px solid white;
}


.color-product-input-active svg{
    display: block;
}


.slider-gallery-modal .swiper-button-prev {
    background-color: #DADADA !important;
    border-radius: 100px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
}


.slider-gallery-modal .swiper-button-prev::after {
    font-size: 1rem;
    font-weight: 900;
    margin-right: -0.2rem;
}



.slider-gallery-modal .swiper-button-next {
    background-color: #DADADA !important;
    border-radius: 100px !important;
    width: 2.75rem !important;
    height: 2.75rem !important;
}



.slider-gallery-modal .swiper-button-next::after {
    font-size: 1rem;
    font-weight: 900;
    margin-left: -0.2rem;
}


.slider-main-gallery .swiper-pagination {
    display: none !important;
}


@keyframes slide {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}


@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}


.animate-slide {
    background: linear-gradient(90deg, #dcdcdc, #f0f0f0, #dcdcdc);
    animation: slide 2s infinite;
}


.animate-shimmer {
    animation: shimmer 2s infinite;
}


.rounded-img-profile-team{
    border-radius: 44px 44px 200px 200px;
}


#box-profile-header{
    position: absolute;
    top: 2.5rem;
    left: 0;
    background-color: white;
    z-index: 20;
    border-radius: 8px;
    width: 16rem;
    box-shadow: 0px 1px 10px 0px #0000001F;
    box-shadow: 0px 5px 8px 0px #00000024;
    box-shadow: 0px 3px 5px -1px #00000033;
    overflow: hidden;
    padding: 0.25rem 0rem;
}


#box-profile-header > a{
    width: 100%;
    padding: 0.75rem 1rem;
    cursor: pointer;
    transition: 0.2s;
}


#box-profile-header > a:hover {
    background-color: #F5F5F5;
}


#box-profile-header > a:nth-child(2){
    border-top: 2px solid #EEEEEE;
    border-bottom: 2px solid #EEEEEE;
}


.img-banner-selider-home{
    border-radius: 16px;
    box-shadow: 0px 0px 20px 16px #7C7C7C42;
}


.item-category-shop{
    box-shadow: 0px 0px 4px 0px #00000026;
}


.fixed-box {
    position: absolute;
    top: 100%;
    z-index: 1000;
    background-color: white;
    padding-bottom: 8px;
    min-width: 200px;
    height: auto;
    box-shadow: 0px 1px 10px 0px #0000001F;
    box-shadow: 0px 5px 8px 0px #00000024;
    box-shadow: 0px 3px 5px -1px #00000033;
    border-radius: 8px;
    z-index: 5;
}


.box-more-search-header{
    position: absolute;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0px 1px 10px 0px #0000001F;
    box-shadow: 0px 5px 8px 0px #00000024;
    box-shadow: 0px 3px 5px -1px #00000033;
    top: 3.25rem;
    background-color: white;
    padding: 0.25rem 1rem;
    z-index: 101;
}

.horizontal-slider {
    width: 100%;
    height: 5px;
    background: #C0C0C0;
    border-radius: 100px;
    margin-bottom: 1rem;
    position: relative;
}

.thumb {
    height: 15px;
    width: 15px;
    background-color: #128E99;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    outline: none;
}

.thumb:hover {
    background-color: #128E99;
}

.track {
    background: #128E99;
    height: 5px;
    top: 0;
    bottom: 0;
    margin-top: 0;
    position: absolute;
    border-radius: 100px;
}

.horizontal-slider .track-2,.horizontal-slider .track-0 {
    background: #C0C0C0;
}


.active-tools-menu{
    box-shadow: 0px 0px 5.81px 0px #0895847A !important;
    border: 0.73px solid #B4FFEF !important;
}


.line-hight-blog * {
    line-height: 1.5rem;
}


.hover-menu-dashboard:hover{
    color: #089584;
}


.hover-menu-dashboard:hover svg path[fill='#666666']{
    fill: #089584;
}


.active-menu-dashboard{
    color: #089584;
}


.active-menu-dashboard svg path[fill='#666666']{
    fill: #089584;
}

.hover-icon-shop-header svg path[fill='#424750']{
    transition: 0.3s;
}

.hover-icon-shop-header:hover svg path[fill='#424750']{
    fill: #089584;
}


.body-articles img:not(.no-style) {
    width: 60% !important;
    height: auto !important;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.body-img img {
    width: 60% !important;
    height: auto !important;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.plyr{
    border-radius: 16px;
    margin: 1rem 0rem;
}


.plyr--audio{
    border-radius: 8px !important;
}


.plyr__control {
    color: #03857f !important;
    border-radius: 8px;
    padding: 8px;
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
}

.plyr__control:hover {
    background-color: #026e66 !important;
    color: #fff !important;
}

.plyr__progress--played {
    background-color: #04d1b1 !important;
}

.plyr__time {
    color: #03857f !important;
}

.plyr__progress--buffer {
    background-color: #c1c1c1 !important;
}

.plyr__control--pressed {
    background-color: #2fa59d5e !important;
}

.plyr__control--pressed svg{
    fill: #ffffff !important;
}

.plyr__controls .plyr__control {
    display: flex;
    align-items: center;
    justify-content: center;
}

.plyr__control svg {
    width: 20px;
    height: 20px;
    fill: #03857f;
}

.plyr__control:hover svg {
    fill: #ffffff !important;
}

.plyr__control:focus {
    outline: none;
}

.plyr__controls {
    display: flex;
    justify-content: space-between;
    gap: 0px;
}

.plyr__control {
    margin: 0 0px;
}


.body-articles audio{
    width: 60%;
}


.body-img audio{
    width: 60%;
}


.ckeditor-html5-video{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ckeditor-html5-video .plyr{
    width: 100%;
}

.ckeditor-html5-video{
    display: flex;
    justify-content: center;
    align-items: center;
}


.ckeditor-html5-video .plyr{
    width: 60%;
}

.ckeditor-html5-audio{
    display: flex;
    justify-content: center;
    align-items: center;
}

.plyr__controls button.plyr__control {
    margin: 0 3px;
}


.ckeditor-html5-audio .plyr{
    width: 60%;
}


.ckeditor-html5-audio .plyr__controls{
    -webkit-box-shadow: inset -1.5px 1.5px 10px 0px rgba(230,230,230,1);
    -moz-box-shadow: inset -1.5px 1.5px 10px 0px rgba(230,230,230,1);
    box-shadow: inset -1.5px 1.5px 10px 0px rgb(207 207 207);
    border-radius: 8px;
}


.plyr__menu--settings {
    position: absolute !important;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
}



.modal-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    padding: 1rem;
    border-bottom: 1px solid #eeeeee;
}


.plyr__menu__container{
    background-color: white !important;
}


/* responseve ............................................................ */


@media screen and (max-width:1279px) {
    footer {
        padding: 2rem 6rem 2rem 6rem;
    }
}


@media screen and (max-width:1023px) {
    .img-banner-selider-home{
        border-radius: 8px;
    }


    #sidbar-main-mobile {
        display: flex;
        height: 100vh;
        width: 85vw;
        position: fixed;
        top: 0;
        right: -85vw;
        z-index: 101;
        background-color: #FFFFFF;
        transition: 0.5s;
        overflow-y: auto;
    }

    #sidbar-category-mobile {
        display: flex;
        height: 100vh;
        width: 85vw;
        position: fixed;
        top: 0;
        right: -85vw;
        z-index: 101;
        background-color: #FFFFFF;
        transition: 0.5s;
        overflow-y: auto;
    }

    #close-sidbar-category-main {
        display: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #0000004D;
        opacity: 0.9;
        z-index: 100;
    }

    #close-sidbar-search-main {
        display: none;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #0000004D;
        opacity: 0.9;
        z-index: 100;
    }

    .active-sidbar-close-mobile {
        display: block !important;
    }


    .active-sidbar-mobile {
        right: 0 !important;
    }

    footer {
        padding: 2rem;
    }

    .swiper-button-next,
    .swiper-button-prev {
        display: none !important;
    }

    .slider-home .swiper-pagination {
        bottom: 0!important;
        background-color: #2121210D !important;
    }

    .slider-home .swiper-pagination-bullet {
        background-color: #5A5E65;
        transition: 0.2s;
    }

    .slider-home .swiper-pagination-bullet-active {
        background-color: #424750CC;
        transform: scale(1.2);
    }

    .icon-item-premium p {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-item-premium p::before {
        content: '';
        display: flex;
        width: 6px;
        height: 6px;
        background-color: #424750ed;
        border-radius: 100px;
        margin-left: 0.5rem;
    }

    .item-team-neireez-social{
        width: 1.7rem;
        height: 1.7rem;
        min-width: 1.7rem;
        border-radius: 5px;
        margin-left: 0.3rem;
        margin-right: 0.3rem;
    }

    .item-team-neireez-social svg{
        width: 18px;
    }

    .slider-main-gallery .swiper-pagination {
        display: block !important;
        position: relative !important;
        margin-top: 1.5rem !important;
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
        width: max-content !important;
        border-radius: 100px !important;
        background-color: #2121210D !important;
    }

    .slider-main-gallery .swiper-pagination-bullet {
        background-color: #5A5E65;
    }

    .slider-main-gallery .swiper-pagination-bullet-active {
        background-color: #424750CC;
    }

    .fixed-box {
        border-radius: 0px !important;
        right: 0 !important;
        width: 100vw;
    }

    .body-articles img:not(.no-style) {
        width: 90% !important;
        height: auto !important;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .body-img img {
        width: 90% !important;
        height: auto !important;
        margin: auto;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .ckeditor-html5-video .plyr{
        width: 100%;
    }
    
    .ckeditor-html5-audio .plyr{
        width: 100%;
    }

    .ckeditor-html5-video .plyr{
        width: 100%;
    }

    .body-img audio{
        width: 100%;
    }

    .plyr__video-wrapper{
        display: flex;
    }
}